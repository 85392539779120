<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1">
        组织架构：
        <tchtree v-model="queryForm.class_id"></tchtree>

        <span v-if="jieciList.length > 0">届次：</span>
        <el-select v-if="jieciList.length > 0" v-model="queryForm.jieci" size="small">
          <el-option label="全部" value="item"></el-option>
          <el-option v-for="(item, i) in jieciList" :key="i" :label="item" :value="item"></el-option>
        </el-select>
        <span style="margin-left:10px">考核周期：</span>
        <el-date-picker size="small" style="width:120px" v-model="queryForm.month" type="month" value-format="yyyy-MM"
                        placeholder="选择月"></el-date-picker>

        <el-button @click="getList" icon="el-icon-search" type="primary" size="small"
                   style="margin-left:10px">查询
        </el-button>
        <span
            style="margin-left: 5px;font-size: 14px;color:red;">该页面内容不是实时数据，点击右侧“重新计算”等候一分钟后刷新页面获取最新数据</span>
      </div>

      <div class="flexStart"
           style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button @click="refreshList" icon="el-icon-refresh" size="small" type="primary" plain
                   style="margin-left:10px">重新计算
        </el-button>
        <el-button @click="exportData" type="text" icon="el-icon-download" size="small">导出当前数据</el-button>
      </div>


    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="DataList" row-key="id" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;" :row-class-name="tableRowClassName">
      <!-- <el-table-column type="index" width="65" label="序号" align="center" /> -->
      <el-table-column prop="idx" label="序号" width="65" align="center"/>

      <el-table-column prop="loginname" label="工号" width="100" align="center"/>
      <el-table-column prop="username" label="姓名" width="100" align="center"/>
      <el-table-column prop="class_name" label="机构" show-overflow-tooltip width="130" align="center"/>

      <el-table-column prop="sx_score" v-if="jxConfig['sx_score']" label="实习率得分" width="150" align="center">

        <template slot-scope="scope">{{ scope.row.sx_score }}--({{ scope.row.sx_percent }})</template>
      </el-table-column>

      <el-table-column prop="zbtj_score" v-if="jxConfig['zbtj_score']" label="周报提交率得分" width="150"
                       align="center">
        <template slot-scope="scope">{{ scope.row.zbtj_score }}--({{ scope.row.wtj_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="zbsh_score" v-if="jxConfig['zbsp_score']" label="周报审核率得分" width="150"
                       align="center">
        <template slot-scope="scope">{{ scope.row.zbsh_score }}--({{ scope.row.wsp_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="ybtj_score" v-if="jxConfig['ybtj_score']" label="月报提交率得分" width="150"
                       align="center">
        <template slot-scope="scope">{{ scope.row.ybtj_score }}--({{ scope.row.mtj_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="ybsh_score" v-if="jxConfig['ybsp_score']" label="月报审核率得分" width="150"
                       align="center">
        <template slot-scope="scope">{{ scope.row.ybsh_score }}--({{ scope.row.msp_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="dk_score" v-if="jxConfig['dk_score']" label="打卡得分" width="150" align="center">

        <template slot-scope="scope">{{ scope.row.dk_score }}--({{ scope.row.dk_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="xf_score" v-if="jxConfig['xf_score']" label="巡访得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.xf_score }}--({{ scope.row.xf_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="xf_num" label="巡访次数" min-width="100" align="center">
        <template slot-scope="scope">{{ scope.row.xf_num || 0 }}</template>
      </el-table-column>
      <el-table-column prop="dwkp_score" v-if="jxConfig['dwkp_score']" label="单位考评得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.dwkp_score }}--({{ scope.row.dwkp_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="sxal_score" v-if="jxConfig['sxal_score']" label="实习案例得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.sxal_score }}--({{ scope.row.sxal_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="sj_score" v-if="jxConfig['sj_score']" label="优秀事迹得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.sj_score }}--({{ scope.row.sj_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="ft_score" v-if="jxConfig['ft_score']" label="访谈得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.ft_score }}--({{ scope.row.ft_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="sxzj_score" v-if="jxConfig['sxzj_score']" label="实习总结得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.sxzj_score }}--({{ scope.row.sxzj_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="sxjh_score" v-if="jxConfig['sxjh_score']" label="实习计划得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.sxjh_score }}--({{ scope.row.sxjh_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="fx_score" v-if="jxConfig['fx_score']" label="返校得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.fx_score }}--({{ scope.row.fx_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="tztj_score" v-if="jxConfig['tztj_score']" label="台账提交得分" width="150" align="center">
        <template slot-scope="scope">{{ scope.row.tztj_score }}--({{ scope.row.tztj_percent || '0.00%' }})</template>
      </el-table-column>
      <el-table-column prop="total_score" label="总得分" width="100" align="center"/>
      <el-table-column prop="compute_month" label="考评月份" width="135" align="center">
        <template slot-scope="scope">{{ scope.row.compute_year }}年 {{ scope.row.compute_month }}月</template>
      </el-table-column>
      <el-table-column prop="cdate" label="考评时间" width="200" align="center"/>

      <!-- <el-table-column fixed="right" label="操作" width="90">
          <template slot-scope="scope">

            <el-button type="text" size="small" @click="viewInfo(scope.row)" v-if="scope.row.report_status == 0">
              审批
            </el-button>

            <el-button v-else type="text" size="small" @click="viewInfo(scope.row)">详情</el-button>

          </template>
        </el-table-column>-->
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

  </div>
</template>

<script>
import util from "../../utils/util";
import tchtree from '../com/tchTree.vue';

var loading = null
export default {
  components: {
    tchtree
  },
  data() {
    return {
      dialogShow: true,
      queryForm: {
        month: util.formatDate(),
        compute_type: 2,
        class_id: "",
        jieci: ""
      },
      page: {
        current_page: 1,
        next_page: 2,
        pre_page: 100,
        per_page: 100,
        total_page: 0,
        count: 0
      },
      DataList: [],
      ckdTreeData: [],
      columnList: [],
      jxConfig: {},//绩效考核配置，绩效分数为0时，不显示此列
      jieciList: [],
      complateTimer: -1,
      checking: false,
      randomCode: '',
    };
  },
  mounted() {
    clearInterval(this.complateTimer)

    this.getJxConfig()
    this.getJieciCOnfig()
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'sumaryStyle';
      }
      return '';
    },
    getJieciCOnfig() {//看下是否配置了按届次分组显示数据
      this.$http.post("/api/sch_config_one", {ktype: "tongji_jieci"}).then(res => {
        if (res.data && res.data.kvalue) {
          this.jieciList = res.data.kvalue.split(",")
        }
      })
    },

    getJxConfig() {
      this.$http.post("/api/performance_appraisal_select", {
        appraisal_type: "2"
      }).then(res => {
        let jxConfig = {}
        for (let item of res.data) {
          jxConfig[item.appraisal_code + "_score"] = item.appraisal_score
        }

        this.jxConfig = jxConfig
        this.getList();
        // console.log(jxConfig)
      })
    },

    getList() {
      clearInterval(this.complateTimer)

      let _this = this
      this.$http
          .post("/api/performance_score_list", {
            page: this.page.current_page,
            pagesize: this.page.per_page,
            compute_year: this.queryForm.month.split("-")[0],
            compute_month: this.queryForm.month.split("-")[1],
            compute_type: this.queryForm.compute_type,
            class_id: this.queryForm.class_id,
            jieci: this.queryForm.jieci
          })
          .then(res => {
            let results = res.data.data
            let dk_rateT = 0, sx_rateT = 0,
                wtj_rateT = 0, wsp_rateT = 0,
                mtj_rateT = 0, msp_rateT = 0, xf_rateT = 0,
                dwkp_rateT = 0, sxal_rateT = 0,
                sj_rateT = 0, ft_rateT = 0,
                sxzj_rateT = 0, sxjh_rateT = 0, fx_rateT = 0,
                tztj_rateT = 0
            let sx_scoreT = 0,
                zbtj_scoreT = 0, zbsh_scoreT = 0,
                ybtj_scoreT = 0, ybsh_scoreT = 0,
                dk_scoreT = 0,
                xf_scoreT = 0, xf_numT = 0,
                dwkp_scoreT = 0, sxal_scoreT = 0,
                ft_scoreT = 0, sj_scoreT = 0,
                sxzj_scoreT = 0, sxjh_scoreT = 0, fx_scoreT = 0,
                tztj_scoreT = 0,
                total_scoreT = 0


            results.map((a, ia) => {
              a.dk_rate = this.$keepTwoDecimal(a.dk_score / this.jxConfig.dk_score).toFixed(2)
              a.dk_percent = this.$keepTwoDecimal((parseFloat(a.dk_rate) * 10000) / 100).toFixed(2) + '%'

              a.sx_rate = this.$keepTwoDecimal(a.sx_score / this.jxConfig.sx_score).toFixed(2)
              a.sx_percent = this.$keepTwoDecimal((parseFloat(a.sx_rate) * 10000) / 100).toFixed(2) + '%'

              a.wtj_rate = this.$keepTwoDecimal(a.zbtj_score / this.jxConfig.zbtj_score).toFixed(2)
              a.wtj_percent = this.$keepTwoDecimal((parseFloat(a.wtj_rate) * 10000) / 100).toFixed(2) + '%'
              a.wsp_rate = this.$keepTwoDecimal(a.zbsh_score / this.jxConfig.zbsp_score).toFixed(2)
              a.wsp_percent = this.$keepTwoDecimal((parseFloat(a.wsp_rate) * 10000) / 100).toFixed(2) + '%'

              a.mtj_rate = this.$keepTwoDecimal(a.ybtj_score / this.jxConfig.ybtj_score).toFixed(2)
              a.mtj_percent = this.$keepTwoDecimal((parseFloat(a.mtj_rate) * 10000) / 100).toFixed(2) + '%'
              a.msp_rate = this.$keepTwoDecimal(a.ybsh_score / this.jxConfig.ybsp_score).toFixed(2)
              a.msp_percent = this.$keepTwoDecimal((parseFloat(a.msp_rate) * 10000) / 100).toFixed(2) + '%'
              if (a.xf_score > this.jxConfig.xf_score) {
                a.xf_rate = 1
              } else {
                a.xf_rate = this.$keepTwoDecimal(a.xf_score / this.jxConfig.xf_score).toFixed(2)
              }
              a.xf_percent = this.$keepTwoDecimal((parseFloat(a.xf_rate) * 10000) / 100).toFixed(2) + '%'

              a.dwkp_rate = this.$keepTwoDecimal(a.dwkp_score / this.jxConfig.dwkp_score).toFixed(2)
              a.dwkp_percent = this.$keepTwoDecimal(parseFloat(a.dwkp_rate) * 10000 / 100).toFixed(2) + '%'

              a.sxal_rate = this.$keepTwoDecimal(a.sxal_score / this.jxConfig.sxal_score).toFixed(2)
              a.sxal_percent = this.$keepTwoDecimal(parseFloat(a.sxal_rate) * 10000 / 100).toFixed(2) + '%'

              a.ft_rate = this.$keepTwoDecimal(a.ft_score / this.jxConfig.ft_score).toFixed(2)
              a.ft_percent = this.$keepTwoDecimal(parseFloat(a.ft_rate) * 10000 / 100).toFixed(2) + '%'

              a.sj_rate = this.$keepTwoDecimal(a.sj_score / this.jxConfig.sj_score).toFixed(2)
              a.sj_percent = this.$keepTwoDecimal(parseFloat(a.sj_rate) * 10000 / 100).toFixed(2) + '%'

              a.sxzj_rate = this.$keepTwoDecimal(a.sxzj_score / this.jxConfig.sxzj_score).toFixed(2)
              a.sxzj_percent = this.$keepTwoDecimal(parseFloat(a.sxzj_rate) * 10000 / 100).toFixed(2) + '%'

              a.sxjh_rate = this.$keepTwoDecimal(a.sxjh_score / this.jxConfig.sxjh_score).toFixed(2)
              a.sxjh_percent = this.$keepTwoDecimal(parseFloat(a.sxjh_rate) * 10000 / 100).toFixed(2) + '%'

              a.fx_rate = this.$keepTwoDecimal(a.fx_score / this.jxConfig.fx_score).toFixed(2)
              a.fx_percent = this.$keepTwoDecimal(parseFloat(a.fx_rate) * 10000 / 100).toFixed(2) + '%'

              a.tztj_rate = this.$keepTwoDecimal(a.tztj_score / this.jxConfig.tztj_score).toFixed(2)
              a.tztj_percent = this.$keepTwoDecimal(parseFloat(a.tztj_rate) * 10000 / 100).toFixed(2) + '%'

              //AVG
              a.idx = ia + 1
              dk_rateT += parseFloat(a.dk_rate || 0)
              sx_rateT += parseFloat(a.sx_rate || 0)
              wtj_rateT += parseFloat(a.wtj_rate || 0)
              wsp_rateT += parseFloat(a.wsp_rate || 0)
              mtj_rateT += parseFloat(a.mtj_rate || 0)
              msp_rateT += parseFloat(a.msp_rate || 0)
              xf_rateT += parseFloat(a.xf_rate || 0)
              dwkp_rateT += parseFloat(a.dwkp_rate || 0)
              ft_rateT += parseFloat(a.ft_rate || 0)
              sxal_rateT += parseFloat(a.sxal_rate || 0)
              sj_rateT += parseFloat(a.sj_rate || 0)
              sxzj_rateT += parseFloat(a.sxzj_rate || 0)
              sxjh_rateT += parseFloat(a.sxjh_rate || 0)
              fx_rateT += parseFloat(a.fx_rate || 0)

              sx_scoreT += parseFloat(a.sx_score || 0)
              zbtj_scoreT += parseFloat(a.zbtj_score || 0)
              zbsh_scoreT += parseFloat(a.zbsh_score || 0)
              ybtj_scoreT += parseFloat(a.ybtj_score || 0)
              ybsh_scoreT += parseFloat(a.ybsh_score || 0)
              dk_scoreT += parseFloat(a.dk_score || 0)
              xf_scoreT += parseFloat(a.xf_score || 0)
              xf_numT += parseFloat(a.xf_num || 0)
              dwkp_scoreT += parseFloat(a.dwkp_score || 0)
              sj_scoreT += parseFloat(a.sj_score || 0)
              ft_scoreT += parseFloat(a.ft_score || 0)
              sxal_scoreT += parseFloat(a.sxal_score || 0)
              sxzj_scoreT += parseFloat(a.sxzj_score || 0)
              sxjh_scoreT += parseFloat(a.sxjh_score || 0)
              fx_scoreT += parseFloat(a.fx_score || 0)
              tztj_scoreT += parseFloat(a.tztj_score || 0)
              total_scoreT += parseFloat(a.total_score || 0)
            })
            if (results && results.length > 0) {
              let avgobj = JSON.parse(JSON.stringify(results[0]))
              let len = res.data.page.count
              avgobj.dk_rate = dk_rateT / len
              avgobj.dk_percent = this.$keepTwoDecimal((parseFloat(avgobj.dk_rate) * 10000) / 100).toFixed(2) + '%'

              avgobj.sx_rate = sx_rateT / len
              avgobj.sx_percent = this.$keepTwoDecimal((parseFloat(avgobj.sx_rate) * 10000) / 100).toFixed(2) + '%'

              avgobj.wtj_rate = wtj_rateT / len
              avgobj.wtj_percent = this.$keepTwoDecimal((parseFloat(avgobj.wtj_rate) * 10000) / 100).toFixed(2) + '%'
              avgobj.wsp_rate = wsp_rateT / len
              avgobj.wsp_percent = this.$keepTwoDecimal((parseFloat(avgobj.wsp_rate) * 10000) / 100).toFixed(2) + '%'

              avgobj.mtj_rate = mtj_rateT / len
              avgobj.mtj_percent = this.$keepTwoDecimal((parseFloat(avgobj.mtj_rate) * 10000) / 100).toFixed(2) + '%'
              avgobj.msp_rate = msp_rateT / len
              avgobj.msp_percent = this.$keepTwoDecimal((parseFloat(avgobj.msp_rate) * 10000) / 100).toFixed(2) + '%'

              avgobj.xf_rate = xf_rateT / len
              avgobj.xf_percent = this.$keepTwoDecimal((parseFloat(avgobj.xf_rate) * 10000) / 100).toFixed(2) + '%'

              avgobj.dwkp_rate = dwkp_rateT / len
              avgobj.dwkp_percent = this.$keepTwoDecimal(parseFloat(avgobj.dwkp_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.sxal_rate = sxal_rateT / len
              avgobj.sxal_percent = this.$keepTwoDecimal(parseFloat(avgobj.sxal_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.ft_rate = ft_rateT / len
              avgobj.ft_percent = this.$keepTwoDecimal(parseFloat(avgobj.ft_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.sj_rate = sj_rateT / len
              avgobj.sj_percent = this.$keepTwoDecimal(parseFloat(avgobj.sj_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.sxzj_rate = sxzj_rateT / len
              avgobj.sxzj_percent = this.$keepTwoDecimal(parseFloat(avgobj.sxzj_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.sxjh_rate = sxjh_rateT / len
              avgobj.sxjh_percent = this.$keepTwoDecimal(parseFloat(avgobj.sxjh_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.fx_rate = fx_rateT / len
              avgobj.fx_percent = this.$keepTwoDecimal(parseFloat(avgobj.fx_rate) * 10000 / 100).toFixed(2) + '%'

              avgobj.tztj_rate = tztj_rateT / len
              avgobj.tztj_percent = this.$keepTwoDecimal(parseFloat(avgobj.tztj_rate) * 10000 / 100).toFixed(2) + '%'


              avgobj.sx_score = (sx_scoreT / len).toFixed(2)
              avgobj.zbtj_score = (zbtj_scoreT / len).toFixed(2)
              avgobj.zbsh_score = (zbsh_scoreT / len).toFixed(2)
              avgobj.ybtj_score = (ybtj_scoreT / len).toFixed(2)
              avgobj.ybsh_score = (ybsh_scoreT / len).toFixed(2)
              avgobj.dk_score = (dk_scoreT / len).toFixed(2)
              avgobj.xf_score = (xf_scoreT / len).toFixed(2)
              avgobj.xf_num = (xf_numT / len).toFixed(2)
              avgobj.dwkp_score = (dwkp_scoreT / len).toFixed(2)
              avgobj.sxal_score = (sxal_scoreT / len).toFixed(2)
              avgobj.ft_score = (ft_scoreT / len).toFixed(2)
              avgobj.sj_score = (sj_scoreT / len).toFixed(2)
              avgobj.sxzj_score = (sxzj_scoreT / len).toFixed(2)
              avgobj.sxjh_score = (sxjh_scoreT / len).toFixed(2)
              avgobj.fx_score = (fx_scoreT / len).toFixed(2)
              avgobj.tztj_score = (tztj_scoreT / len).toFixed(2)
              avgobj.total_score = (total_scoreT / len).toFixed(2)

              avgobj.idx = ''
              avgobj.id = 0
              avgobj.flag = '平均'
              avgobj.loginname = ''
              avgobj.username = '平均值'
              avgobj.class_name = ''
              results.unshift(avgobj)
            }
            this.DataList = results;
            this.page = res.data.page;
          });
    },
    handlePageChange(val) {
      this.page.current_page = val
      this.getList(val)
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList(1)
    },
    refreshList() {
      let _this = this
      clearInterval(_this.complateTimer)
      let code = '' + (parseInt(Math.random() * 1000000) + 1000000);
      code = code.substring(1, 7);

      this.randomCode = code
      this.$http
          .post("/api/performance_calculation", {
            year: this.queryForm.month.split("-")[0],
            month: this.queryForm.month.split("-")[1],
            //compute_type: 2,
            progress_mark: this.randomCode
          })
          .then(res => {
            _this.$message.success("后台计算中，请等待1分钟左右刷新页面")
            // this.getList();
            if (!_this.checking) {
              _this.checking = true
              loading = _this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.3)'
              });
              _this.checkComplate();
            }
          });
      setTimeout(() => {
        clearInterval(_this.complateTimer)
        loading.close();
      }, 300000)
    },
    checkComplate() {
      let _this = this
      this.complateTimer = setInterval(() => {

        _this.$http.get(`/api/progress_status?progress_mark=${_this.randomCode}`).then(res => {
          console.log(408, res)
          if (res.data && res.data.data == 'resolved') {
            _this.checking = false
            _this.$message.success('计算成功，计算结果已更新~');
            clearInterval(_this.complateTimer)
            loading.close();
            _this.getList()
          } else if (res.data && res.data.data == 'failed') {
            _this.checking = false
            _this.$message.error('计算失败，请重试~');
            clearInterval(_this.complateTimer)
            loading.close();
          }
        })
      }, 5000)
    },
    beforeDestroy() {
      clearInterval(this.complateTimer)
    },
    exportData() {
      this.$http
          .post("/api/export_jxkh_data", {
            page: 1,
            pagesize: 2000,
            compute_year: this.queryForm.month.split("-")[0],
            compute_month: this.queryForm.month.split("-")[1],
            compute_type: this.queryForm.compute_type,
            class_id: this.queryForm.class_id,
            jieci: this.queryForm.jieci
          })
          .then(res => {
            window.open(res.data.url, "_blank");
          });
    }
  }
};
</script>

<style lang="less">
.sumaryStyle td .cell {
  color: #2469f3;
  font-weight: 700
}

/*.sumaryStyle td:nth-of-type(2) .cell{
 color: #f56c6c;
}*/
</style>